@import "src/utils/index";

.select {
  display: block;
  position: relative;
  width: 100%;

  &__hideInput{
    width: 0;
    height: 0;
    border: none;
    outline: none;
    position: absolute;
    z-index: -1;
  }

  &__label {
    margin-bottom: 2px;

    @include text($weight-400, $f-size-12, $black, $line-height-160);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 41px;

    @include input();

    &:hover {
      cursor: pointer;
    }
  }

  &__output {
    display: inline-block;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    @include text($weight-400, $f-size-14, $black, $line-height-160);

    &::placeholder {
      @include text($weight-400, $f-size-14, $grey-200, $line-height-160)
    }
  }

  &__placeholder {
    @include text($weight-400, $f-size-14, $grey-200, $line-height-160)
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    background: $white;
    border: 1px solid $grey-300;
    box-shadow: 0 16px 40px rgba(144, 144, 163, 0.12);
    border-radius: 8px;
    position: absolute;
    top: 22px;
    left: 0;
    right: 0;
    z-index: 1000;
    max-height: 185px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 7px 12px;

    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);

    &:hover {
      background-color: $grey-400;
      color: $black;
      cursor: pointer;
    }
  }

  &__itemActive {
    color: $black;
  }
}

.searchList {
  position: absolute;
  top: 14px;
  left: 0;
  right: 0;
  padding: 5px;
  z-index: 9999;
  //max-height: 250px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid $grey-300;
  box-shadow: 0 16px 40px rgba(144, 144, 163, 0.12);
  background: $white;
  max-height: 250px;
  height: fit-content;
}

.list {
  list-style: none;
  margin-top: 5px;
  height: 185px;
  padding: 10px 0;
  overflow-y: auto;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb{
    background-color: $blue;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track{
    background-color: $grey-400;
  }

  &__item {
    padding: 7px 12px;

    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);

    &:hover {
      background-color: $grey-400;
      color: $black;
      cursor: pointer;
    }

    &__active{
      background-color: $grey-400;
      color: $black;
    }
  }
}

.input {
  width: 100%;

  @include text($weight-400, $f-size-14, $black, $line-height-160);
  @include input();
}
