@import "src/utils/index";

.timer {
  display: flex;
  align-items: center;
  margin-top: 6px;

  &_item{
    display: inline-block;
    @include text($weight-400, $f-size-12, $grey-100, $line-height-120);
  }

  &__button{
    border: none;
    outline: none;
    background-color: transparent;
    @include text($weight-400, $f-size-12, $blue, $line-height-170);

    &:hover{ cursor: pointer;}

  }
}

