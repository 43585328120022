@import "src/utils/index";

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $grey-400;
  padding-bottom: 55px;
  overflow-x: auto;

  @include media--min($width--phone-l) {
    padding-bottom: 68px;
  }

  @include media--min($width--tablet) {
    padding-bottom: 0;
    overflow-x: hidden;
  }
}

.scrollTopButton{
  position: fixed;
  bottom: 75px;
  right: 20px;

  @include media--min($width--laptop-wrap){
    bottom: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $grey-400;

  @include media--min($width--tablet) {
    margin: 0 auto;
    padding: 40px 0;
    flex-direction: row;
  }

  &__box {

    &:nth-child(1) {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 73px;

      @include media--min($width--tablet) {
        position: static;
        display: block;
        width: 225px;
        height: auto;
        margin-right: 20px;
      }

      @include media--min($width--laptop-wrap) {
        width: 225px;
      }

      @include media--min($width--laptop) {
        width: 289px;
      }

      @include media--min($width--laptop-X) {
        width: 317px;
      }

      @include media--min($width--desktop) {
        width: 293px;
      }
    }

    &:nth-child(2) {
      @include layoutRightBox();
      margin-top: 20px;

      @include media--min($width--tablet) {
       margin-top: 0;
      }
    }
  }
}

.contentColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1760px;
  width: 100%;

  @include media--min($width--laptop-wrap){
    margin: 0 auto;
    padding: 10px 20px;
  }

}

.desktop {
  background-color: $white;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //display: none;

  @include media--min($width--tablet) {
    display: flex;
    align-items: center;
  }

  &__rightBox {
    margin-left: auto;
    display: flex;
    align-items: center;

    &:empty{
      display: none;
    }
  }

  &__link{
    @include text($weight-400, $f-size-14,$black, $line-height-160);
    margin-right: 32px;

    & svg {
      margin-right: 8px;
    }
  }
}


.footer {
  display: flex;
  flex-direction: column;
  padding: 17px 24px;
  background-color: $white;

  @include media--min($width--phone-l) {
    flex-direction: row;
    align-items: center;
  }


  &__link {
    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);

    &:last-of-type {
      @include media--min($width--phone-l) {
        margin-left: 32px;
      }
    }
  }
}
