@import "src/utils/index";

.disks {
  padding-top: 10px;
  &__title{
    @include pageTitle();

    padding: 16px;
    display: flex;
    align-items: flex-start;

    @include media--min($width--laptop-wrap){
      padding: 20px;
    }
  }

  &__count{
    display: inline-block;
    margin-left: 8px;
    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);
  }
}

