@import "src/utils/index";


.sidebarList {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  border: 1px solid $grey-300;
  overflow: hidden;
  background-color: $white;
  transform: translateY(-130%);
  position: relative;
  transition: .5s;

  &Show {
    transform: translateY(0%);
    transition: .5s;
    z-index: 1000;
  }

  @include media--min($width--tablet) {
    transform: translateY(0%);
    border-radius: 16px;
  }

  &__item {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-top: 1px solid $grey-300;
    padding: 20px;
    text-align: left;

    @include text($weight-400, $f-size-14, $black, $line-height-160);

    &:hover {
      background-color: $grey-400;
    }

    &Logout {
      color: $grey-100;
    }

    &Active {
      color: $blue;
    }

    @include media--min($width--laptop-wrap) {
      border: none;
      padding: 10px 24px;

      &:nth-child(1) {
        padding-top: 20px;
      }

      &:nth-child(5) {
        padding-bottom: 20px;
      }
    }
  }
}

.sidebarMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  background-color: $white;
  position: relative;
  z-index: 1001;

  &__title {
    @include text($weight-700, $f-size-32, $black, $line-height-130);

    @include media--min($width--phone-l) {
      @include text($weight-700, $f-size-40, $black, $line-height-130);
    }
  }

  @include media--min($width--tablet) {
    display: none;
  }
}
