@import "src/utils/index";

.filter {
  width: 205px;

  input{
    max-width: 100%;
    width: 100%;
  }

  ul{
    overflow: hidden;
  }

  &__clearBtn{
    display: inline-block;
    padding: 10px 3px;
    @include text($weight-400, $f-size-12, $grey-100, $line-height-160);

    &:hover{cursor: pointer;}
  }
}
