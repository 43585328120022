@import "src/utils/index";

.filter {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: $white;
  padding: 20px;

  @include media--min($width--laptop-wrap) {
    position: static;
    border: 1px solid $grey-300;
    border-radius: 16px;
    z-index: 1;
  }

  &Hide {
    display: none;
  }
}


.button {
  padding: 3px;
  background-color: $white;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;

    @include media--min($width--laptop-wrap) {
      display: none;
    }
  }

  &__title {
    @include pageTitle();
  }

  &__row {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-left: -6px;
    margin-right: -6px;
    padding-bottom: 16px;

    &:last-of-type {
      padding-top: 8px;
    }

    @include media--min($width--laptop-wrap) {
      flex-wrap: nowrap;
    }

    &MobileColumn {
      flex-direction: column;

      @include media--min($width--laptop-wrap) {
        flex-direction: row;
      }
    }
  }

  &__box {
    padding: 0 6px;
    width: 100%;

    &:nth-child(6) {
      height: 34px;
      padding-top: 12px;

      @include media--min($width--phone-l) {
        padding-top: 0;
      }
    }

    &Grow {
      width: 100%;

      @include media--min($width--phone-l) {
        width: 33.3%;
      }

      @include media--min($width--laptop-wrap) {
        flex-grow: 0;
        width: 16.66%;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;

    @include media--min($width--laptop-wrap) {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &__button {
    width: 100%;

    &:last-of-type {
      margin-top: 12px;
    }

    @include media--min($width--laptop-wrap) {
      width: 291px;

      &:last-of-type {
        width: 200px;
        margin-top: 0;
      }
    }
  }
}

.table {
  margin-top: 20px;


  @include table();

  &__header {
    padding: 20px 24px 12px;
    border-bottom: 1px solid $grey-300;
    width: 100%;
    min-width: $width--tablet;
  }

  &__head {
    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);
  }

  &__body {
    overflow-x: auto;
    width: 100%;
    overflow-y: hidden;
  }

  &__footer {
    padding: 20px 24px;
    margin-top: 20px;
  }
}

.tableCell {
  display: inline-flex;

  &:nth-child(1) {
    width: 60px;
    margin-right: 60px;
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    width: 20%;
  }

  &:nth-child(5) {
    width: 10%;
  }
}

.tableRow {
  display: flex;
  align-items: center;
  min-width: $width--tablet;
  overflow-x: auto;
  width: 100%;
  padding: 20px 24px 12px;

  &:hover{
    background-color: $grey-400;
    cursor: pointer;
  }

  &__image {
    width: 60px;
  }

  &__text {
    @include text($weight-400, $f-size-14, $black, $line-height-160);
  }
}


.tableItem {
  width: 30%;

  &:nth-child(1) {
    padding-left: 124px;
  }

  &:nth-child(2),
  &:nth-child(3) {
    width: 20%;
  }

  &:nth-child(4) {
    width: 30%;
  }
}
