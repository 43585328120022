@import "src/utils/index";

.navigation {
  display: flex;
  @include media--min($width--tablet) {
    padding-left: 34px;
  }

  &__item {
    @include text($weight-400, $f-size-16, $black, $line-height-160);
    margin: 0 0 0 24px;
    border-bottom: 2px solid transparent;
    padding: 12px 0 10px;
    @include media--min($width--phone-l) {
      margin: 0 18px;
    }

    &:hover {
      cursor: pointer
    }

    &Active{
      @include text($weight-700, $f-size-16, $blue, $line-height-160);
      border-bottom: 2px solid $blue;
    }
  }
}

.navigationMobile {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  height: 55px;
  border-top: 1px solid $grey-300;
  z-index: 150;

  @include media--min($width--phone-l) {
    height: 68px;
  }

  @include media--min($width--tablet) {
    display: none;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
    padding: 9px 0 10px;

    & svg{
      height: 30px;
    }

    @include text($weight-400, 8px, $black, $line-height-120);

    @include media--min($width--phone-l) {
      padding: 15px 0 10px;

      @include text($weight-400, $f-size-12, $black, $line-height-120);
    }
  }
}
