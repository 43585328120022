@import "src/utils/index";

.registration {
  width: 100%;
  height: 100vh;
  background-color: $background;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  @include modal();

  &__title{
    margin-bottom: 32px;

    @include text($weight-700, $f-size-32, $black, $line-height-130);
    @include media--min($width--phone-l){
      @include text($weight-700, $f-size-40, $black, $line-height-120);
    }
  }

  &__orText{
    text-align: center;
    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);
  }

  &__box{
    padding-bottom: 12px;

    &:nth-child(5){
      flex-grow: 1;
      @include media--min($width--phone-l){
        flex-grow: 0;
      }
    }

  }
}


.generatePassword{
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  @include text($weight-400, $f-size-14, $blue, $line-height-160);
}
