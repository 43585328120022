@import "src/utils/index";

.tooltip {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &__textWrap {
    position: absolute;
    bottom: 20px;
    background: $white;
    border: 1px solid $grey-300;
    box-shadow: 0 16px 40px rgba(58, 32, 229, 0.16);
    border-radius: 16px;
    padding: 20px 24px;

  }

  &__text{
    @include text($weight-400, $f-size-14, $black, $line-height-160);
  }
}

