@import "src/utils/index";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $background;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 900;
  }

  &__content{
    position: relative;
    z-index: 1000;
  }
}

