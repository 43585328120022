@import "src/utils/index";

.wrapper{
  width: 100%;
  border: 1px solid $grey-200;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.pagination {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0;

  & li {
    width: 45px;
    height: 45px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid $grey-200;
    border-right: 1px solid $grey-200;

    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);

    &:hover{
      cursor: pointer;
      background-color: $grey-400;
    }

    &:empty{
      display: none;
    }

    @include media--min($width--tablet) {
      width: 80px;
      height: 48px;
    }
  }

  & li.rc-pagination-item-active {
    background-color: $grey-400;
    @include text($weight-400, $f-size-14, $black, $line-height-160)
  }
}
