@import "src/utils/index";


.profile {
  background: $white;
  border: 1px solid $grey-300;
  margin-top: 20px;

  @include media--min($width--tablet) {
    border-radius: 16px;
    margin-top: 0;
  }

  &__header {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 24px;

    @include media--min($width--phone-l) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__box{
      display: flex;
      align-items: center;
    }
  }

  &__box{
    display: flex;
    flex-direction: column;


    @include media--min($width--phone-l){
      align-items: flex-end;
      //padding: 0 24px;
    }

    &:nth-child(3){
      justify-content: flex-start;
      margin-left: auto;
      align-items: flex-end;
    }
  }
}

.data{
  &__text{
    @include text($weight-400, $f-size-12, $grey-100, $line-height-170)
  }
}
