@import "src/utils/index";

.codeInput {
  display: inline-block;
  &__code{
    & input {
      @include text($weight-400, $f-size-14, $grey-100, $line-height-170);
    }
  }
  &__codeError{
    & input {
      @include text($weight-400, $f-size-14, $grey-100, $line-height-170);
      border-color: $red;
    }
  }
}

