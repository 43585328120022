@import "src/utils/index";

.formSubmitButton {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: $white;
  display: flex;
  align-items: center;
  padding: 12px 20px;

  @include media--min($width--tablet) {
    padding: 12px 0;
  }

  &__inner{
    @include layoutRightBox();

    & button{
      width: 100%;

      @include media--min($width--tablet) {
        width: 280px;
      }
    }
  }
}

