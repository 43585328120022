@import "src/utils/index";

.rulesLinks {
  display: inline-block;

  &__text{

    @include text($weight-400, $f-size-12, $grey-100, $line-height-170);
  }

  &__link{
    margin: 0 3px;
    text-decoration: none;

    @include text($weight-400, $f-size-12, $blue, $line-height-170);
  }
}

