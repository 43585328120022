@import "src/utils/index";

.details {
  display: none;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 300;
  padding-bottom: 50px;

  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(9, 10, 10, 0.44);
    z-index: 999;
    position: absolute;
  }

  &__visible {
    display: block;
  }

  &__content {
    max-width: 1001px;
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 20px;
    background: $white;
    box-shadow: 0 16px 40px rgba(58, 32, 229, 0.16);
    transform: translateX(0%);

    animation: .5s hide 0s ease-in-out forwards;
    z-index: 1000;


    &Show {
      transform: translateX(100%);
      animation: .5s show 0s ease-in-out forwards;
    }
  }
}

@keyframes show {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes hide {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
