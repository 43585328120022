@import "src/utils/index";

.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  width: 60px;
  height: 60px;
  margin: 20px 0;

  @include media--min($width--phone-l) {
    width: 100px;
    height: 100px;
  }

  &:hover {
    cursor: pointer;

    .avatar__img {
      filter: brightness(0.6);
    }

    .avatar__icon {
      z-index: 4;
    }
  }

  &__imgWrap {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-color: $grey-200;
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    border-radius: 50%;
    overflow: hidden;
    object-position: center;
    object-fit: cover;
  }

  &__icon {
    position: absolute;
    z-index: 2;
  }

  &__boxName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 24px;
    width: 76%;
  }

  &__name {
    @include text($weight-700, $f-size-24, $black, $line-height-130);

    @include media--min($width--phone-l) {
      @include text($weight-700, $f-size-40, $black, $line-height-120);
    }
  }

  &__removeImage {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    margin: 0;

    @include text($weight-400, $f-size-14, $grey-100, $line-height-160)
  }
}


.avatarM {
  display: flex;
  align-items: center;
  position: relative;

  &__imgWrap {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    background-color: $grey-200;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  &__name {
    margin-left: 10px;
    @include text($weight-400, $f-size-16, $black, $line-height-160);

  }

  &__list{
    display: flex;
    position: absolute;
    top: 20px;
    right: 0;
    width: 250px;
    background: $white;
    border: 1px solid $grey-300;
    box-shadow: 0 16px 40px rgba(58, 32, 229, 0.16);
    border-radius: 16px;
    overflow: hidden;
    flex-direction: column;
    padding: 0;
    margin: 0;
    z-index: 100;
  }

  &__item{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    padding: 10px 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;

    @include text($weight-400, $f-size-14, $black, $line-height-160);

    &:hover {
      background-color: $grey-400;
    }

    &Active {
      color: $blue;
    }

    &Logout {
      color: $grey-100;
    }

    @include media--min($width--laptop-wrap) {
      border: none;
      padding: 10px 24px;

      &:nth-child(1) {
        padding-top: 20px;
      }

      &:nth-child(4) {
        padding-bottom: 20px;
      }
    }
  }
}

.avatarS {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;

  &__imgWrap {
    width: 24px;
    height: 24px;
    background-color: $grey-200;
  }

  &__img {
    width: 100%;
    height: 100%;
  }
}
