@import "src/utils/index";

.select {
  display: block;
  position: relative;
  //width: 100%;

  &__hideInput{
    width: 0;
    height: 0;
    border: none;
    outline: none;
    position: absolute;
    z-index: -1;
  }

  &__label {
    margin-bottom: 2px;

    @include text($weight-400, $f-size-12, $black, $line-height-160);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 41px;

    @include input();

    &:hover {
      cursor: pointer;
    }
  }

  &__output {
    display: inline-block;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    @include text($weight-400, $f-size-14, $black, $line-height-160);

    &::placeholder {
      @include text($weight-400, $f-size-14, $grey-200, $line-height-160)
    }
  }

  &__placeholder {
    @include text($weight-400, $f-size-14, $grey-200, $line-height-160)
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    background: $white;
    border: 1px solid $grey-300;
    box-shadow: 0 16px 40px rgba(144, 144, 163, 0.12);
    border-radius: 8px;
    position: absolute;
    top: 22px;
    left: 0;
    right: 0;
    z-index: 1000;
    max-height: 185px;
    overflow-y: scroll;
    overflow-x: hidden;

  }

  &__item {
    display: flex;
    align-items: center;
    padding: 7px 12px;

    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);

    &:hover {
      background-color: $grey-400;
      color: $black;
      cursor: pointer;
    }
  }

  &__itemActive {
    color: $black;
  }
}

.checkbox {
  display: inline-flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__label {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;

    @include text($weight-400, $f-size-14, $black, $line-height-160);

    & svg {
      margin-left: 10px;
    }
  }
}

.radioButton {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__label {
    display: inline-block;
    margin-left: 8px;

    @include text($weight-400, $f-size-14, $black, $line-height-160)
  }
}

.inputField {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &__inner {
    position: relative;
    width: 100%;
  }

  &__label {
    margin-bottom: 2px;

    @include text($weight-400, $f-size-12, $black, $line-height-160);
  }

  &__input {
    width: 100%;

    @include text($weight-400, $f-size-14, $black, $line-height-160);
    @include input();
  }
}

.errorText {
  text-align: right;
  margin-top: 0;
  margin-bottom: 0;

  @include text($weight-400, $f-size-12, $red, $line-height-170);
}

.colorActive {
  color: $blue;
}

.colorError {
  color: $red;
}

.filling {
  @include button('filling')
}

.outline {
  @include button('outline')
}

.link {
  @include button('link')
}

.light {
  @include button('light')
}

.vk {
  @include button('vk')
}

.arrowLeft {
  @include button('arrowLeft')
}

.hideInput{
  width: 0;
  height: 0;
  outline: none;
  border: none;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
