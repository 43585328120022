@import "src/utils/index";

.phone {
  display: flex;
  flex-direction: column;
  position: relative;

  &__inner{
    position: relative;
    width: 100%;
  }

  &__label {
    margin-bottom: 2px;

    @include text($weight-400, $f-size-14, $black, $line-height-170);
  }

  &__input {
    width: 100%;

    @include text($weight-400, $f-size-14, $black, $line-height-160);
    @include input();
  }

  &__changeText{
    @include text($weight-400, $f-size-12, $grey-100, $line-height-170);
    text-align: right;
  }

  //&__code{
  //  & input {
  //    @include text($weight-400, $f-size-14, $grey-100, $line-height-170);
  //  }
  //}

  &__button{
    border: none;
    outline: none;
    background-color: transparent;
    @include text($weight-400, $f-size-12, $blue, $line-height-170);

    &:hover{ cursor: pointer;}
  }
}

.errorText{
  text-align: right;
  margin-top: 5px;
  margin-bottom: 0;

  @include text($weight-400, $f-size-12, $red, $line-height-170);
}

.colorActive{
  color: $blue;
}

.colorError{
  color: $red;
}
