@import "src/utils/index";

$widthBox: 130px;

.table {
  display: block;
  background-color: $white;
  border: 1px solid $grey-300;
  border-radius: 16px;
  margin-top: 20px;
  overflow-y: hidden;
  overflow-x: auto;

  &__body{
    width: 100%;

    @include media--max($width--tablet) {
      min-width: $width--tablet;
    }
  }

  &__filter {
    padding: 24px;
    display: flex;

    & .fields__select{
      width: 100%;
    }
  }

  &__footer {
    padding: 20px 24px;
    background-color: $white;
    border: 1px solid $grey-300;
    border-radius: 16px;
    margin-top: 20px;

    &:empty{
      display: none;
    }
  }

  &Card{
    background-color: transparent;
    border: 1px solid transparent;

    .table__body{
      min-width: auto;
      width: 100%;
      padding-right: 0;
    }

    .table__bodyCard{
      min-width: 1001px;
      width: 100%;
      padding-right: 0;
    }

    .table__filter{
      background-color: $white;
      border: 1px solid $grey-300;
      border-radius: 16px;
      margin-bottom: 10px;
    }

    .table__footer {
      background-color: $white;
      border: 1px solid $grey-300;
      border-radius: 16px;
    }
  }

  &__inner{
    width: 100%;
    display: flex;
    flex-direction: column;

    &Flex{
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 0;
  border-bottom: 1px solid $grey-300;
}

.head {
  @include text($weight-400, $f-size-14, $grey-100, $line-height-160);
}

.text {
  @include text($weight-400, $f-size-16, $black, $line-height-160);

  &__children {
    display: flex;
    align-items: center;
  }
}

.tableItem {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 1px solid $grey-300;
  padding-right: 20px;

  &:hover{
    background-color: $grey-400;
  }

  &Bg{
    background-color: #F4F2FF;
  }

  &Card{
    width: 100%;
    border-bottom: none;

    @include media--min($width--phone-l) {
      width: 32%;
      margin-right: 1.3%;
    }

    @include media--min($width--tablet) {
      width: 24%;
      margin-right: 1.3%;

      &:nth-child(4n){
        margin-right: 0;
      }
    }

    @include media--min($width--laptop-XL) {
      width: 16.2%;
      margin-right: 0.5%;

      &:nth-child(4n){
        margin-right: 0.5%;
      }

      &:nth-child(6n){
        margin-right: 0;
      }
    }
  }
}

.tableItemHeader {
  width: 100%;

  &Active {
    background-color: $grey-400;
    //border-top: 1px solid $grey-100;
  }

  &__inner {
    display: flex;
    align-items: center;
    padding: 12px 0;

    &List{
      &:hover{cursor: pointer}
    }
  }
}

.box {
  padding: 0 6px;

  &__name {
    max-width: 570px;
    width: 100%;
    display: flex;
    align-items: center;

    & .text {
      margin-left: 20px;
      width: 332px;
    }
  }

  &__remainder {
    width: $widthBox;
    text-align: center;
  }

  &__wholesale {
    width: $widthBox;
    text-align: right;
  }

  &__retail {
    width: $widthBox;
    text-align: right
  }

  &__empty {
    width: $widthBox;
    text-align: center;
  }

  &__sm {
    width: 50px;
    text-align: center;
  }

}

.image {
  width: 80px;
  height: 80px;
  background-color: $white;
  border-radius: 8px;
  overflow: hidden;

  & img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    object-position: center;
  }
}

.accordionContent {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
  background-color: $grey-400;
  border-bottom: 1px solid $grey-100;
}

.accordionContent[aria-expanded="true"] {
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  border: none;
}

.innerHeader {
  display: flex;
  align-items: center;
  padding: 12px 0;
}

.innerlist{
  height: calc(68vh - 80px);
  overflow-x: auto;

  @include media--min($width--laptop-wrap) {
    height: calc(68vh - 50px);
  }
}

.innerContent {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid $grey-300;

  &:last-of-type{
    border-bottom: 1px solid $grey-300;
  }
}

.innerProvider {
  width: 140px;
}

.innerRemoteness {
  width: 140px;
  text-align: center;
}

.innerYear {
  width: 100px;
  text-align: center;
}

.innerRemainder {
  width: 100px;
  text-align: center;
}

.innerWholesale {
  width: 90px;
  text-align: right;
}

.innerRetail {
  width: 130px;
  text-align: right;
  position: relative;
}

.data {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  padding: 0 23px;

  &__empty {
    //width: 342px;
  }

  &__text {
    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);

    display: flex;
    align-items: center;

    &:nth-child(1) {
      margin-right: 63px;
    }

    &:nth-child(2) {
      &:hover {
        cursor: pointer;
      }
    }
  }
}


.item {
  max-width: 1001px;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: $white;
  box-shadow: 0 16px 40px rgba(58, 32, 229, 0.16);
  transform: translateX(100%);
  transition: .5s;


  .table{
    border: none;
    margin-top: 0;
    &__body{
      padding-bottom: 30px;
      height: calc(100vh - 122px)
    }
  }

  &__visible{
    transform: translateX(0);
    transition: .5s;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    padding: 12px;

    @include media--min($width--phone-l) {
      padding: 24px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &Bg{
    background-color: #F4F2FF;
  }
}

.productPrev {
  padding: 16px 24px;
  border-bottom: 1px solid $grey-300;
  display: flex;
  align-items: center;

  &__image {
    width: 80px;
    height: 80px;

    & img {
      width: 100%;
    }
  }

  &__title {
    @include text($weight-400, $f-size-14, $black, $line-height-160);
  }
}

.card{
  width: 100%;
  min-height: 430px;
  background-color: $white;
  border: 1px solid $grey-300;
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  &:hover{
    cursor: pointer;
  }

  &__body{
    padding: 24px 24px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__image{
    width: 200px;
    height: 200px;
    object-fit: contain;
  }

  &__title{
    @include text($weight-700, $f-size-14, $black, $line-height-160);
    margin-top: 12px;
  }

  &__footer{
    margin-top: 16px;
    padding: 10px 24px 12px;
    border-top: 1px solid $grey-300;
    display: flex;
    align-items: center;


    &__text{
      @include text($weight-400, $f-size-12, $grey-100, $line-height-170);
      flex-grow: 1;
    }
  }
}

.cardColor{
  display: flex;
  align-items: center;
  margin-top: 4px;

  &__text{
    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);
  }
}

.cardPrice{
  display: flex;
  align-items: center;
  margin-top: 6px;

  &__from,
  &__to{
    @include text($weight-400, $f-size-14, $black, $line-height-160);
  }

  &__to{
    margin-left: 12px;
  }
}

.notFound{
  background: $white;
  border: 1px solid $grey-300;
  border-radius: 16px;
  padding: 66px 10px 40px 10px;
  margin-top: 20px;
  text-align: center;

  &__title{
    @include text($weight-700, $f-size-24, $black, $line-height-160);
  }

  &__text{
    @include text($weight-400, $f-size-14, $black, $line-height-160);
  }
}
