@import "src/utils/index";

.tabs {
  overflow: hidden;

  @include media--min($width--phone-l) {
    overflow: visible;
  }

  &__item {
    padding: 24px;
  }
}

.buttons {
  display: flex;
  transition: .5s;

  @include media--min($width--phone-l) {
    padding: 0 24px;
    transition: 0s;
  }

  &__item {
    padding: 7px 24px;
    background-color: $white;
    cursor: pointer;
    width: 100%;

    @include text($weight-400, $f-size-14, $black, $line-height-160);

    &:first-of-type {
      border: 1px solid $grey-200;
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type {
      border: 1px solid $grey-200;
      border-radius: 0 8px 8px 0;
    }
  }
}

.activeButton {
  background-color: $grey-400;
  color: $grey-100;
}

.basicInformation {
  &__title {
    @include text($weight-700, $f-size-14, $black, $line-height-160);
    margin: 8px 0 16px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    @include media--min($width--phone-l) {
      flex-direction: row;
    }
  }

  &__boxL {
    width: 100%;
    @include media--min($width--phone-l) {
      width: calc(50% - 6px);
      margin-right: auto;
    }
  }

  &__boxR {
    width: 100%;
    @include media--min($width--phone-l) {
      width: calc(50% - 6px);
      margin-left: auto;
    }
  }
}

.rating {
  padding: 32px 0 0;

  &__title {
    @include text($weight-700, $f-size-14, $black, $line-height-160);
    margin-bottom: 16px;
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $grey-200;
    border-radius: 8px;
    padding: 7px 16px;
    width: 150px;
    height: 40px;
  }

  &__text {
    @include text($weight-400, $f-size-14, $black, 16px);
  }
}


.company {
  background-color: $white;
  border: 1px solid $grey-300;
  border-radius: 8px;
  padding: 16px 20px;
  margin-bottom: 12px;

  @include media--min($width--phone-l) {
    padding: 20px 24px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 60px;
    height: 38px;

    & img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__box {
    padding: 4px 16px;
    width: 80%;

    @include media--min($width--phone-l) {
      width: 70%;
    }
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include text($weight-700, $f-size-14, $black, $line-height-160);
  }

  &__text {
    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);

    @include media--max($width--phone-sm) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__email {
    @include text($weight-400, $f-size-14, $black, $line-height-160);
  }
}
