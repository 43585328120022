@import "variables";
@import "breakpoints";

@mixin text(
  $weight: $weight-400,
  $size : $f-size-14,
  $color: $black,
  $line-height: $line-height-120
) {
  font-family: $f-main;
  font-weight: $weight;
  font-size: $size;
  font-style: normal;
  color: $color;
  line-height: $line-height;
}

@mixin input() {
  background: $grey-400;
  border: 1px solid;
  border-radius: 8px;
  padding: 7px 12px;
  outline: none;
  height: 35px;
}

@mixin button($type) {
  padding: 11.5px 22px;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  @if ($type == 'filling') {
    background-color: $blue;
    border: none;

    @include text($weight-700, $f-size-14, $white, $line-height-120);

    &:hover{
      background-color: $blue-light;
    }

    &:active{
      background-color: $blue-dark;
    }

    &:focus{
      outline: 4px solid $border;
    }

    &:disabled {
      background-color: $grey-300;
      cursor: default;
    }
  }

  @if($type == 'outline') {
    background-color: $white;
    border: 1px solid $blue;

    @include text($weight-400, $f-size-14, $blue, $line-height-120);

    &:hover{
      background-color: $blue;
      color: $white;
      & path {
        stroke: $white;
      }
    }

    &:active{
      background-color: $blue;
      color: $white;
    }

    &:focus{
      outline: 4px solid $border;
      border: 1px solid transparent;
    }

    &:disabled {
      border: 1px solid $grey-300;
      color: $grey-200;
      cursor: default;
    }
  }

  @if($type == 'link') {
    background-color: transparent;
    border: none;

    @include text($weight-400, $f-size-14, $blue, $line-height-120);

    &:disabled {
      color: $grey-200;
      cursor: default;
    }
  }

  @if($type == 'light') {
    border: 1px solid $grey-200;
    background-color: $white;

    @include text($weight-400, $f-size-14, $grey-100, $line-height-120);

    &:hover{
      border: 1px solid $blue;
      color: $blue;
    }

    &:active{
      border: 1px solid $blue;
      color: $blue;
    }

    &:focus{
      outline: 4px solid $border-light;
      border: 1px solid transparent;
    }

    &:disabled {
      border: 1px solid $grey-300;
      color: $grey-200;
      cursor: default;
    }
  }

  @if($type == 'vk') {
    border: 1px solid $grey-200;
    background-color: $white;
    display: flex;
    align-items: center;

    @include text($weight-400, $f-size-14, $color-vk, $line-height-120);

    &:hover{
      background-color: $color-vk;
      color: $white;
    }

    &:active{
      background-color: $color-vk;
      color: $white;
    }

    &:focus{
      outline: 4px solid $border-light;
      border: 1px solid transparent;
    }

    &:disabled {
      border: 1px solid $grey-300;
      color: $grey-200;
      cursor: default;
    }
  }

  @if($type == 'arrowLeft') {
    border: 1px solid $grey-200;
    background-color: $white;
    display: flex;
    align-items: center;

    @include text($weight-400, $f-size-14, $grey-100, $line-height-120);

    &:hover{
      border: 1px solid $blue;
      color: $blue;
    }

    &:active{
      border: 1px solid $blue;
      color: $blue;
    }

    &:focus{
      outline: 4px solid $border-light;
      border: 1px solid transparent;
    }

    &:disabled {
      border: 1px solid $grey-300;
      color: $grey-200;
      cursor: default;
    }
  }
}


@mixin modal($width: 400px) {
  background-color: $white;
  padding: 20px 24px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media--min($width--phone-l){
    width: $width;
    height: auto;
    border-radius: 16px;
  }
}

@mixin layoutRightBox(){
  width: 100%;

  @include media--min($width--tablet) {
    padding-top: 0;
  }

  @include media--min($width--laptop-wrap) {
    width: 715px;
  }

  @include media--min($width--laptop) {
    width: 907px;
  }

  @include media--min($width--laptop-X) {
    width: 989px;
  }
  @include media--min($width--desktop) {
    width: 918px;
  }
}


@mixin pageTitle() {
  @include text($weight-700, $f-size-32, $black, $line-height-120);

  @include media--min($width--phone-l){
    @include text($weight-700, $f-size-40, $black, $line-height-120);
  }
}


@mixin table() {
  background: $white;
  border: 1px solid $grey-300;
  border-radius: 16px;
  overflow: hidden;
}

@mixin row1(){
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -6px;
  margin-right: -6px;


  &:last-of-type{
    padding-top: 8px;
  }

  @include media--min($width--phone-l) {
   // padding-bottom: 16px;
  }


  @include media--min($width--laptop) {
    flex-wrap: nowrap;
  }
}

@mixin col1() {
  width: 100%;
  flex-wrap: wrap;

  @include media--min($width--phone-l) {
    width: 100%;
  }

  @include media--min($width--laptop){
    flex-wrap: wrap;
    width: 40%;
  }

  @include media--min($width--laptop-XL){
    width: 35%;
  }
}

@mixin col2(){
  width: 100%;
  padding: 18px 0;

  @include media--min($width--laptop){
    width: 100%;
    padding-top: 0;
  }

  @include media--min($width--laptop-X) {
    width: 45%;
  }
}

@mixin col3(){
  width: 100%;
  flex-wrap: wrap;

  @include media--min($width--laptop){
    width: 53%;
  }

  @include media--min($width--laptop-X){
    width: 56%;
  }
  @include media--min($width--laptop-XL){
    width: 50%;
  }
}
