@import "src/utils/index";

.wrapper{
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

.error{
  background-color: $red;
}

.success{
  background-color: $green;
}

.alert {
  padding: 20px;
  display: flex;
  align-items: center;

  &__text{
    margin-right: 20px;
    @include text($weight-400, $f-size-14, $white, $line-height-160);
  }
}

