@import "src/utils/index";

.filter {
  display: flex;
  flex-direction: column;
  background: $white;
  border: 1px solid $grey-300;
  border-radius: 16px;
  padding: 24px 20px;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  overflow-x: scroll;
  min-height: 180px;

  @include media--min($width--laptop-wrap) {
    position: static;
    overflow: visible;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media--min($width--laptop-wrap) {
      display: none;
    }
  }

  &__loader{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;

    @include media--min($width--laptop-wrap) {
      display: none;
    }
  }

  &__title {
    @include pageTitle();
  }

  &__tire{
    display: none;
    @include media--min($width--phone-l) {
      display: inline-block;
      margin-top: 27px;
      color: $grey-100;
    }
  }

  &__row {
    @include row1();
    @include media--min($width--laptop) {
      flex-wrap: wrap;
    }
    width: 100%;
    position: relative;

    &:nth-child(3) {
      align-items: flex-end;
    }
  }

  &__col {
    display: flex;
    flex-wrap: wrap;
  }

  &__colParams {
    width: 100%;

    @include media--min($width--laptop-X) {
      width: 77%;
    }

    &__inner {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      &Rear {
        margin-top: 20px;

        &:empty {
          display: none;
        }
      }

      & .form__box {
        width: 50%;

        @include media--min($width--phone-l) {
          width: 18%;
        }
        @include media--min($width--laptop-wrap) {
          width: 11.8%;
        }
      }
    }
  }

  &__colData {
    width: 100%;
    margin-top: 20px;

    @include media--min($width--phone-l) {
      width: 60%;
    }
    @include media--min($width--laptop) {
      width: 40%;
      //margin-top: 32px;
    }

    @include media--min($width--laptop-X) {
      margin-top: 0;
      width: 23%;
    }

    & .form__box {
      width: 100%;
      @include media--min($width--phone-l){
        width: 50%;
      }
    }
  }

  &__colPrice {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    @include media--min($width--phone-l) {
      width: 50%;
    }

    @include media--min($width--laptop-X) {
      width: 25%;
    }

    & .form__box {
      width: 33.3%;
    }
  }

  &__colNewS {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include media--min($width--phone-s) {
      flex-direction: row;
    }
    @include media--min($width--phone-l) {

      width: 50%;
    }

    @include media--min($width--laptop-X) {
      width: 33%;
    }

    & .form__box {
      width: 100%;


      @include media--min($width--phone-l) {
        width: 50%;
      }
      @include media--min($width--laptop-X) {
        width: 100%;
      }
    }
  }

  &__colCheckboxes {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-top: 20px;

    @include media--min($width--phone-s) {
      flex-direction: row;
      align-items: flex-end;
    }

    @include media--min($width--laptop) {
      width: 40%;
      padding-left: 0;
    }

    @include media--min($width--laptop-X) {
      padding-left: 20px;
    }

    @include media--min($width--laptop-X) {
      margin-top: 0;
    }

    & .form__box {
      width: 100%;
    }

    & .form__checkbox {
      padding-bottom: 5px;
    }
  }

  &__checkbox {
    padding: 8px 8px 8px 0;

    @include media--min($width--phone-l) {
      padding: 0 4px;
    }
  }

  &__colBottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 24px;
  }

  &__box {
    padding: 0 6px;

    &Sparka {
      margin-top: 27px;
    }
  }
}

.switcher {
  display: none;

  @include media--min($width--tablet) {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
  }
}

.space {
  width: 0;
  @include media--min($width--tablet) {
    width: 6px;
  }
  @include media--min($width--laptop-X) {
    width: 4px;
  }
  @include media--min( $width--laptop-Ios) {
    width: 16px;
  }
}

.colors {
  padding: 8px;
  display: flex;

  &__count {
    margin-left: 7px;
    display: inline-block;

    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);

    &Active {
      @include text($weight-400, $f-size-14, $blue, $line-height-160);
    }
  }
}

.buttonsWrap {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: auto;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;

  @include media--min($width--laptop-wrap) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.switchButton{
  padding: 3px;
  background-color: $white;
  text-align: center;
}

.button {
  &:nth-child(1) {
    width: 100%;
    @include media--min($width--laptop-wrap) {
      width: 223px;
      margin-right: auto;
    }
  }

  &:nth-child(2) {
    width: 100%;
    margin-top: 12px;

    @include media--min($width--laptop-wrap) {
      width: 230px;
      margin-top: 0;
      margin-right: 20px;
    }

    @include media--min($width--laptop) {
      width: 208px;
    }
  }

  &:nth-child(3) {
    width: 100%;
    margin-top: 12px;

    @include media--min($width--laptop-wrap) {
      width: 230px;
      margin-top: 0;
    }

    @include media--min($width--laptop) {
      width: 208px;
    }
  }
}
