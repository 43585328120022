@import "src/utils/index";

.successCheck {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 8px 40px rgba(9, 214, 111, 0.4);
  background: $green;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

