@import "src/utils/index";

.filter {
  display: flex;
  flex-direction: column;
  background: $white;
  border: 1px solid $grey-300;
  border-radius: 16px;
  padding: 24px 20px;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  overflow-x: scroll;
  min-height: 180px;
  height: 100%;

  @include media--min($width--laptop-wrap) {
    position: static;
    overflow: visible;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media--min($width--laptop-wrap) {
      display: none;
    }
  }

  &__loader{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.row {
  display: flex;
  flex-direction: column;

  @include media--min($width--laptop-wrap) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.col {
  display: flex;
  flex-wrap: wrap;

  &:nth-child(1) {
    width: 100%;
    @include media--min($width--laptop-wrap) {
      width: 53%;
    }
    @include media--min($width--laptop-X) {
      width: 40%;
    }
  }

  &:nth-child(2) {
    width: 100%;
    @include media--min($width--phone-l) {
      width: 73.2%;
    }
    @include media--min($width--laptop-wrap) {
      width: 47%;
    }
    @include media--min($width--laptop-X) {
      width: 58%;
    }
  }
}

.box {
  width: 100%;
  display: inline-flex;
  align-items: flex-end;

  &:nth-child(4) {
    padding-bottom: 7px;
    margin-top: 20px;

    @include media--min($width--laptop-wrap) {
      margin-top: 0;
    }
  }

  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    @include media--min($width--phone-l) {
      margin-top: 20px;
    }
  }

  @include media--min($width--phone-l) {
    width: 22%;
    margin-right: 10px;
  }

  @include media--min($width--laptop-X) {
    width: 23%;
  }
}

.boxData {
  width: 100%;
  display: inline-flex;
  align-items: flex-end;
  margin-top: 20px;

  @include media--min($width--phone-l) {
    width: 30.1%;
    margin-right: 10px;
  }

  @include media--min($width--laptop-wrap) {
    margin-top: 0;
  }

  @include media--min($width--laptop-X) {
    &:nth-child(1),
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 30%;
    }
  }
}

.dataAdditionalRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;

  @include media--min($width--phone-l) {
    flex-direction: row;
    align-items: flex-end;
  }
}

.dataAdditional {
  width: 100%;

  @include media--min($width--phone-l) {
    width: 20%;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    width: 100%;

    @include media--min($width--phone-l){
      width: 18%;
      margin-right: 10px;
    }

    @include media--min($width--tablet) {
      width: 11.7%;
    }

    @include media--min($width--laptop-X) {
      width: 9.2%;
      margin-right: 10px;
    }
  }

  &:nth-child(4) {
    @include media--min($width--phone-l) {
      width: 40%;
    }
    @include media--min($width--tablet) {
      width: 20%;
    }
  }

  &:nth-child(5),
  &:nth-child(6) {
    margin-top: 20px;
    width: auto;
    @include media--min($width--tablet) {
      padding: 0 7px 7px;
    }
  }
}

.bottomCheckboxes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.checkboxWrapper {
  margin-right: 15px;
  margin-top: 20px;
   @include media--min($width--laptop){
     width: 28.3%;
     &:nth-child(1){
       width: 28.3%;
     }
     &:nth-child(2){
        width: 19.7%;
     }
   }
}

.buttonsWrap {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: auto;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;

  @include media--min($width--laptop-wrap) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.switchButton{
  padding: 3px;
  background-color: $white;
  text-align: center;
}

.button {
  &:nth-child(1) {
    width: 100%;
    @include media--min($width--laptop-wrap) {
      width: 223px;
      margin-right: auto;
    }
  }

  &:nth-child(2) {
    width: 100%;
    margin-top: 12px;

    @include media--min($width--laptop-wrap) {
      width: 230px;
      margin-top: 0;
      margin-right: 20px;
    }

    @include media--min($width--laptop) {
      width: 208px;
    }
  }

  &:nth-child(3) {
    width: 100%;
    margin-top: 12px;

    @include media--min($width--laptop-wrap) {
      width: 230px;
      margin-top: 0;
    }

    @include media--min($width--laptop) {
      width: 208px;
    }
  }
}
