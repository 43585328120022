@import "src/utils/index";

.searchList {
  position: absolute;
  top: 19px;
  left: 0;
  right: 0;
  padding: 5px;
  z-index: 9999;
  //max-height: 250px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid $grey-300;
  box-shadow: 0 16px 40px rgba(144, 144, 163, 0.12);
  background: $white;
  max-height: 250px;
}

.list {
  list-style: none;
  margin-top: 5px;
  height: 185px;
  padding: 10px 0;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb{
    background-color: $blue;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track{
    background-color: $grey-400;
  }

  &__item {
    padding: 7px 12px;

    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);

    &:hover {
      background-color: $grey-400;
      color: $black;
      cursor: pointer;
    }
  }
}
