.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    animation: spin 2s infinite linear;
  }
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}


