@import "src/utils/index";

.recoveryPassword {
  width: 100%;
  height: 100vh;
  background-color: $background;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  @include modal();

  &__title{
    margin-bottom: 32px;

    @include text($weight-700, $f-size-32, $black, $line-height-130);
    @include media--min($width--phone-l){
      @include text($weight-700, $f-size-40, $black, $line-height-120);
    }
  }

  &__orText{
    text-align: center;
    @include text($weight-400, $f-size-14, $grey-100, $line-height-160);
  }

  &__box{
    padding-bottom: 12px;

    &:nth-child(2){
      flex-grow: 1;
      @include media--min($width--phone-l){
        flex-grow: 0;
      }
    }
  }
}

.formSuccess{
  @include modal(520px);

  justify-content: center;

  &__title{
    text-align: center;
    margin: 12px 0;

    @include text($weight-700, $f-size-32, $black, $line-height-130);
    @include media--min($width--phone-l){
      @include text($weight-700, $f-size-40, $black, $line-height-120);
    }
  }

  &__text{
    text-align: center;
    display: flex;
    flex-direction: column;

    @include text($weight-400, $f-size-14, $black, $line-height-120);

    &Email{
      display: inline-block;
      margin-top: 3px;
      color: $blue;
    }
  }


}
