@import "src/utils/index";

.company{
  overflow-x: scroll;
  height: 100%;
  padding-bottom: 75px;

  @include media--min($width--phone-l) {
    padding-bottom: 0;
  }

  &__dateRegistration{
    @include text($weight-400, $f-size-12, $grey-100, $line-height-170);
  }

  &__close {
    display: flex;
    justify-content: space-between;
    padding: 24px;

    &:hover {
      cursor: pointer;
    }
  }

  &__body{
    padding: 24px;
  }

  &__header{
    display: flex;
    align-items: flex-end;
  }

  &__image{
    width: 100px;
  }

  &__title{
    @include text($weight-700, $f-size-40, $black, $line-height-120);
    padding: 0 24px;
  }

  &__subtitle{
    @include text($weight-700, $f-size-14, $black, $line-height-160);
    margin-top: 32px;
  }

  &__address{
    @include text($weight-400, $f-size-14, $black, $line-height-160);
    padding: 0 24px;
  }

  &__text{
    @include text($weight-400, $f-size-14, $black, $line-height-160);
  }

  &__info{
    display: flex;
    flex-wrap: wrap;
  }
}




.buttons{
  display: flex;
  transition: .5s;
  margin: 42px 0 0;

  @include media--min($width--phone-l){
    transition: 0s;
  }

  &__item{
    padding: 7px 24px;
    background-color: $white;
    cursor: pointer;
    width: 100%;

    @include text($weight-400, $f-size-14, $black, $line-height-160);

    &:first-of-type{
      border: 1px solid $grey-200;
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type{
      border: 1px solid $grey-200;
      border-radius: 0 8px 8px 0;
    }
  }
}

.activeButton{
  background-color: $grey-400;
  color: $grey-100;
}


.dataItem{
  margin-top: 16px;
  width: 100%;

  @include media--min($width--phone-l) {
    width: 50%;
  }

  &__Label{
    display: block;
    @include text($weight-400, $f-size-12, $grey-100, $line-height-170);
  }

  &__text{
    display: block;
    @include text($weight-400, $f-size-14, $black, $line-height-160);
  }
}
